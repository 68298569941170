import PropTypes from 'prop-types'
import { Alignment, Box, variants } from '@resident-advisor/design-system'
import unescape from 'lodash/unescape'
import CmsContent from '@/components/generic/cms-content'
import Heading from '@/components/generic/heading'
import Person from '@/components/generic/person'
import Link from '@/components/generic/link'
import FeatureFilmMeta from '@/components/editorial/cards/feature-film-meta'

const FeatureCardContent = ({
  author,
  blurb,
  linkProps,
  series,
  title,
  date,
}) => (
  <Alignment flexDirection="column">
    <Heading>
      <FeatureFilmMeta series={series} date={date} />
      <Box mb={2}>
        <Link color="primary" variant={variants.text.heading.m} {...linkProps}>
          {unescape(title)}
        </Link>
      </Box>
    </Heading>

    <Box mb={2}>
      <CmsContent content={blurb} />
    </Box>

    {author?.name && (
      <Person
        name={author.name}
        imageUrl={author.imageUrl}
        username={author.username}
        contributor={author.contributor}
      />
    )}
  </Alignment>
)

FeatureCardContent.propTypes = {
  author: PropTypes.object,
  blurb: PropTypes.string.isRequired,
  linkProps: PropTypes.shape({
    href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }).isRequired,
  title: PropTypes.string.isRequired,
  series: PropTypes.shape({
    id: PropTypes.string,
    seriesRef: PropTypes.string,
    title: PropTypes.string,
    contentUrl: PropTypes.string,
  }),
  date: PropTypes.string.isRequired,
}

export default FeatureCardContent
