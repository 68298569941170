import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import { djPastEventsByYearRoute } from 'lib/routes'
import { variants } from '@resident-advisor/design-system'
import EventsArchive from '@/components/events/sub-sections/events-archive'
import QueryResultHandler from '@/components/generic/query-result-handler'
import GET_ARTIST_EVENTS_ARCHIVE from './GetArtistEventsArchiveQuery'

const ArtistEventsArchive = ({ artistId, ...props }) => {
  const { asPath } = useRouter()
  const { loading, error, data } = useQuery(GET_ARTIST_EVENTS_ARCHIVE, {
    variables: { id: artistId },
  })

  return (
    <QueryResultHandler
      loading={loading}
      error={error}
      data={data}
      dataKey="artist.events"
      markup={Card}
      linkProps={djPastEventsByYearRoute(`${asPath}/past-events`)}
      {...props}
    />
  )
}

const Card = (props) => (
  <EventsArchive variant={variants.section.secondary} {...props} />
)

ArtistEventsArchive.propTypes = {
  artistId: PropTypes.string.isRequired,
}

export default ArtistEventsArchive
