import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import { djTourdatesByYearRoute, djPastEventsByYearRoute } from 'lib/routes'
import { variants, Section, colors } from '@resident-advisor/design-system'
import { useRouter } from 'next/router'
import Ad from '@/components/generic/ad'
import QueryResultHandler from '@/components/generic/query-result-handler'
import { dark } from '@/themes'
import Listing from '@/components/shared/listing'
import FilteredEventsListing, {
  EventsListingType,
} from '@/components/events/sections/filtered-events-listing'
import Seo from '@/components/generic/seo/Seo'
import GET_ARTIST_EVENTS_TAB from './GetArtistEventsTabQuery'
import ArtistDetailSubNav, {
  artistDetailsSubNavPropType,
} from '../ArtistDetailSubNav'
import { useArtistSEO, ARTIST_PAGE_TYPE } from '../artistSeoUtils'

const FIRST_EVENT_YEAR = 2001

const ArtistEventsTab = ({ slug, subNavOptions, eventsListingType }) => {
  const queryResult = useQuery(GET_ARTIST_EVENTS_TAB, {
    variables: {
      slug,
    },
  })

  return (
    <ThemeProvider theme={dark}>
      <Section variant={variants.section.secondary}>
        <QueryResultHandler
          {...queryResult}
          dataKey="artist"
          markup={ArtistEventsTabMarkup}
          subNavOptions={subNavOptions}
          eventsListingType={eventsListingType}
          slug={slug}
        />
      </Section>
    </ThemeProvider>
  )
}

ArtistEventsTab.propTypes = {
  slug: PropTypes.string,
  subNavOptions: artistDetailsSubNavPropType,
  eventsListingType: PropTypes.oneOf(Object.values(EventsListingType)),
}

const ArtistEventsTabMarkup = ({
  data: artist,
  subNavOptions,
  eventsListingType,
}) => {
  const {
    query: { slug, page, ...queryFilters }, // ignore the slug/page params as they're not filtering
  } = useRouter()

  // Should only index non-filtered pages
  const noIndex = Object.entries(queryFilters).length > 0

  return (
    <>
      <Seo
        {...useArtistSEO({
          data: artist,
          pageType:
            eventsListingType === EventsListingType.Archive
              ? ARTIST_PAGE_TYPE.pastEvents
              : ARTIST_PAGE_TYPE.upcomingEvents,
          noIndex,
        })}
      />
      <FilteredEventsListing
        backgroundColor={colors.black}
        id={artist.id}
        name={artist.name}
        baseFilterType={Listing.Aggregations.Artist}
        listingFilterRouteFormatter={
          eventsListingType === EventsListingType.Archive
            ? djPastEventsByYearRoute
            : djTourdatesByYearRoute
        }
        createSubNav={(filters) => (
          <ArtistDetailSubNav
            subNavFilters={filters}
            contentUrl={artist.contentUrl}
            subNavOptions={subNavOptions}
          />
        )}
        includeCountryAggregation
        YearlyArchiveSecondaryComponent={() => (
          <Ad
            variant={Ad.variants.MPU_General_Large}
            justifyContent="flex-end"
          />
        )}
        eventsListingType={eventsListingType}
        archiveLinkRouteProps={djPastEventsByYearRoute(
          `${artist.contentUrl}/past-events`
        )}
      />
    </>
  )
}

ArtistEventsTabMarkup.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    contentUrl: PropTypes.string.isRequired,
  }).isRequired,
  subNavOptions: artistDetailsSubNavPropType,
  eventsListingType: PropTypes.oneOf(Object.values(EventsListingType)),
}

export { ArtistEventsTabMarkup, FIRST_EVENT_YEAR }
export default ArtistEventsTab
