import { useQuery } from '@apollo/client'
import { useIntl } from 'react-intl'
import { SubSection, variants } from '@resident-advisor/design-system'
import PropTypes from 'prop-types'
import LabelCard from '@/components/events/cards/label'
import QueryResultHandler from '@/components/generic/query-result-handler'
import HorizontalList from '@/components/generic/horizontal-list'
import messages from '@/messages/dict'
import GET_ARTIST_LABELS from './getArtistLabelsQuery'

const ArtistLabels = ({ artistId }) => {
  const { loading, error, data } = useQuery(GET_ARTIST_LABELS, {
    variables: { id: artistId },
  })

  return (
    <QueryResultHandler
      loading={loading}
      error={error}
      data={data}
      dataKey="artist.labels"
      markup={ArtistLabelsMarkup}
    />
  )
}

ArtistLabels.propTypes = {
  artistId: PropTypes.string.isRequired,
}

const ArtistLabelsMarkup = ({ data: labels }) => {
  const intl = useIntl()

  return (
    <SubSection.Stacked
      title={intl.formatMessage(messages.labels)}
      variant={variants.section.secondary}
    >
      <HorizontalList Card={LabelCard} mCols={3} mWrap lWrap items={labels} />
    </SubSection.Stacked>
  )
}

ArtistLabelsMarkup.propTypes = {
  data: PropTypes.array,
}

export { ArtistLabels as default, ArtistLabelsMarkup }
