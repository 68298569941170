import PropTypes from 'prop-types'
import { Alignment } from '@resident-advisor/design-system'
import MetaText from '@/components/generic/meta-text'

const StatCard = ({ children, Icon, iconLabel }) => (
  <Alignment flexDirection="column">
    <MetaText Icon={Icon} text={iconLabel} />
    {children}
  </Alignment>
)

StatCard.propTypes = {
  children: PropTypes.node.isRequired,
  Icon: PropTypes.elementType.isRequired,
  iconLabel: PropTypes.string.isRequired,
}

export default StatCard
