import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import dict from '@/messages/dict'

import { TextColumn } from '@/components/generic/detail-bar'

const useArtistNameColumn = ({ firstName, lastName, name, pronouns }) => {
  const intl = useIntl()

  // null safe concatenation
  const realName = [firstName, lastName].filter((x) => x).join(' ')

  const showRealName = (firstName || lastName) && realName !== name

  if (showRealName && pronouns) {
    return {
      title: intl.formatMessage(dict.realName),
      text: `${realName} (${pronouns})`,
      show: true,
    }
  }

  if (!showRealName && pronouns) {
    return {
      title: intl.formatMessage(dict.pronouns),
      text: pronouns,
      show: true,
    }
  }

  if (showRealName && !pronouns) {
    return {
      title: intl.formatMessage(dict.realName),
      text: realName,
      show: true,
    }
  }

  return { show: false }
}

const ArtistNameColumn = ({ firstName, lastName, name, pronouns }) => {
  const { show, title, text } = useArtistNameColumn({
    firstName,
    lastName,
    name,
    pronouns,
  })

  if (!show) {
    return null
  }

  return <TextColumn text={text} title={title} lSpan={2} maxLines={3} />
}

ArtistNameColumn.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  name: PropTypes.string,
  pronouns: PropTypes.string,
}

export default ArtistNameColumn
