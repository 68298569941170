import PropTypes from 'prop-types'
import arrayHasData from 'lib/arrayHasData'
import getFeatureImage from 'lib/getFeatureImage'
import Link from '@/components/generic/link'
import ResponsiveImage from '@/components/generic/responsive-image'

const FeatureCardImage = ({ linkProps, images, lazyRootRef }) =>
  arrayHasData(images) && (
    <Link noStyle {...linkProps}>
      <ResponsiveImage
        url={getFeatureImage(images)}
        aspect="132%"
        lazyRootRef={lazyRootRef}
        sizes={{ s: '50vw', m: '25vw' }}
      />
    </Link>
  )

FeatureCardImage.propTypes = {
  linkProps: PropTypes.shape({
    href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }).isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  lazyRootRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
}

export default FeatureCardImage
