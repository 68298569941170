import { gql } from '@apollo/client'
import { ARTIST_BIOGRAPHY_FRAGMENT } from '@/queries/GetArtistBySlugQuery'

const GET_ARTIST_BIOGRAPHY = gql`
  query GET_ARTIST_BIOGRAPHY($id: ID!) {
    artist(id: $id) {
      ...biographyFields
    }
  }
  ${ARTIST_BIOGRAPHY_FRAGMENT}
`

export default GET_ARTIST_BIOGRAPHY
