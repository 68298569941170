import ArtistLinks from '@/interfaces/gql/ArtistLinks'

const buildArtistLinks = (links: ArtistLinks) =>
  [
    {
      type: ArtistLinkType.Website,
      href: links.website,
    },
    {
      type: ArtistLinkType.Instagram,
      href: links.instagram,
    },
    {
      type: ArtistLinkType.Bandcamp,
      href: links.bandcamp,
    },
    {
      type: ArtistLinkType.Soundcloud,
      href: links.soundcloud,
    },
    {
      type: ArtistLinkType.Twitter,
      href: links.twitter,
    },
    {
      type: ArtistLinkType.Facebook,
      href: links.facebook,
    },
    {
      type: ArtistLinkType.Discogs,
      href: links.discogs,
    },
  ].filter((link) => !!link.href)

enum ArtistLinkType {
  Website = 'Website',
  Twitter = 'Twitter',
  Soundcloud = 'Soundcloud',
  Discogs = 'Discogs',
  Facebook = 'Facebook',
  Instagram = 'Instagram',
  Bandcamp = 'Bandcamp',
}

export { ArtistLinkType }
export default buildArtistLinks
