import { useQuery } from '@apollo/client'
import { SubSection, Grid, Column } from '@resident-advisor/design-system'
import { PropTypes } from 'prop-types'
import QueryResultHandler from '@/components/generic/query-result-handler'
import GET_ARTIST_STATS from './GetArtistStatsQuery'
import {
  FirstEventYear,
  RegionsMostPlayed,
  VenuesMostPlayed,
} from './components'

const ArtistStats = ({ artistId }) => {
  const { loading, error, data } = useQuery(GET_ARTIST_STATS, {
    variables: { id: artistId },
  })

  return (
    <QueryResultHandler
      loading={loading}
      error={error}
      data={data}
      dataKey="artist"
      markup={ArtistStatsMarkup}
    />
  )
}

ArtistStats.propTypes = {
  artistId: PropTypes.string.isRequired,
}

const ArtistStatsMarkup = ({ data }) => {
  const { firstEvent, regionsMostPlayed, venuesMostPlayed } = data

  return (
    <SubSection.Stacked title="Stats">
      <Grid sWrap mWrap lCols={9} mCols={6} sCols={1}>
        <Column lSpan={3} mSpan={2} sSpan={1}>
          <FirstEventYear firstEvent={firstEvent} />
        </Column>
        <Column lSpan={3} mSpan={2} sSpan={1}>
          <RegionsMostPlayed regions={regionsMostPlayed} />
        </Column>
        <Column lSpan={3} mSpan={2} sSpan={1}>
          <VenuesMostPlayed venues={venuesMostPlayed} />
        </Column>
      </Grid>
    </SubSection.Stacked>
  )
}

ArtistStatsMarkup.propTypes = {
  data: PropTypes.shape({
    firstEvent: PropTypes.object,
    regionsMostPlayed: PropTypes.PropTypes.arrayOf(PropTypes.object),
    venuesMostPlayed: PropTypes.PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
}

export default ArtistStats
export { ArtistStatsMarkup }
