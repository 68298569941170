import { useQuery } from '@apollo/client'
import { useIntl } from 'react-intl'
import { SubSection } from '@resident-advisor/design-system'
import PropTypes from 'prop-types'
import { reviewByIdRoute } from 'lib/routes'
import MusicCard from '@/components/editorial/cards/music'
import QueryResultHandler from '@/components/generic/query-result-handler'
import HorizontalList from '@/components/generic/horizontal-list'
import messages from '@/messages/music'
import GET_ARTIST_REVIEWS from './getArtistReviewsQuery'

const ArtistReviews = ({ artistId }) => {
  const { loading, error, data } = useQuery(GET_ARTIST_REVIEWS, {
    variables: { id: artistId },
  })

  return (
    <QueryResultHandler
      loading={loading}
      error={error}
      data={data}
      dataKey="artist.reviews"
      markup={ArtistReviewsMarkup}
    />
  )
}

ArtistReviews.propTypes = {
  artistId: PropTypes.string.isRequired,
}

const ArtistReviewsMarkup = ({ data }) => {
  const intl = useIntl()

  return (
    <SubSection.Stacked title={intl.formatMessage(messages.reviews)}>
      <HorizontalList
        Card={(props) => (
          <MusicCard urlFormatter={reviewByIdRoute} {...props} />
        )}
        mCols={4}
        items={data}
      />
    </SubSection.Stacked>
  )
}

ArtistReviewsMarkup.propTypes = {
  data: PropTypes.array.isRequired,
}

export { ArtistReviews as default, ArtistReviewsMarkup }
