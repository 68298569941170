import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import { useIntl } from 'react-intl'
import dict from '@/messages/dict'
import { FOLLOW_BUTTON_TYPES } from '@/components/shared/follow-button'
import { dark } from '@/themes'
import trackingIds from '@/tracking/tracking-ids'

import DetailBar, {
  CountryColumn,
  CmsContentColumn,
  FollowDetailColumn,
  LinksColumn,
} from '@/components/generic/detail-bar'
import ArtistNameColumn from './artist-name-column'

const ArtistDetailBar = ({
  id,
  name,
  firstName,
  lastName,
  aliases,
  isFollowing,
  followerCount,
  country,
  residentCountry,
  links,
  pronouns,
}) => {
  const intl = useIntl()

  const showResidentAndHomeCountry =
    residentCountry && country && country.id !== residentCountry.id

  return (
    <ThemeProvider theme={dark}>
      <DetailBar
        gridProps={{
          sWrap: true,
          mWrap: true,
          sCols: 2,
          mCols: 2,
          lCols: 12,
        }}
        dataTrackingId={trackingIds.artistDetailBar}
      >
        <ArtistNameColumn
          firstName={firstName}
          lastName={lastName}
          name={name}
          pronouns={pronouns}
        />
        <CmsContentColumn
          title={intl.formatMessage(dict.aliases)}
          content={aliases}
          lSpan={2}
        />
        {showResidentAndHomeCountry ? (
          <CountryColumn
            title={intl.formatMessage(dict.location)}
            country={country}
            residentCountry={residentCountry}
            sSpan={2}
            mSpan={2}
            lSpan={3}
          />
        ) : (
          <CountryColumn
            title={intl.formatMessage(dict.location)}
            country={country || residentCountry}
            sSpan={2}
            mSpan={2}
            lSpan={3}
          />
        )}
        <LinksColumn
          title={intl.formatMessage(dict.links)}
          items={links.map((link) => ({
            href: link.href,
            name: link.type,
          }))}
          sSpan={2}
          mSpan={2}
        />
        <FollowDetailColumn
          id={id}
          isFollowing={isFollowing}
          type={FOLLOW_BUTTON_TYPES.artist}
          followerCount={followerCount}
          sSpan={2}
          mSpan={2}
          lSpan={3}
        />
      </DetailBar>
    </ThemeProvider>
  )
}

ArtistDetailBar.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  aliases: PropTypes.string,
  country: PropTypes.object,
  residentCountry: PropTypes.object,
  followerCount: PropTypes.number,
  isFollowing: PropTypes.bool,
  links: PropTypes.array,
  pronouns: PropTypes.string,
}

export default ArtistDetailBar
