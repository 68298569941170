import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import arrayHasData from 'lib/arrayHasData'
import {
  SubSection,
  Grid,
  Column,
  variants,
} from '@resident-advisor/design-system'
import { useIntl } from 'react-intl'
import { reviewByIdRoute } from 'lib/routes'
import { useRouter } from 'next/router'
import QueryResultHandler from '@/components/generic/query-result-handler'
import MusicCard from '@/components/editorial/cards/music'
import messages from '@/messages/music'
import dict from '@/messages/dict'
import { PaddedStack } from '@/components/generic/padded'
import Seo from '@/components/generic/seo/Seo'
import GET_ARTIST_EDITORIAL from './GetArtistEditorialQuery'
import ArtistDetailPageTemplate from '../ArtistDetailPageTemplate'
import ArtistFeaturesFilmsSubSection from './ArtistFeaturesFilmsSubSection'
import { artistDetailsSubNavPropType } from '../ArtistDetailSubNav'
import { useArtistSEO, ARTIST_PAGE_TYPE } from '../artistSeoUtils'

const ArtistRAEditorialTab = ({ id, subNavOptions }) => {
  const queryResult = useQuery(GET_ARTIST_EDITORIAL, {
    variables: {
      id,
    },
  })

  return (
    <QueryResultHandler
      dataKey="artist"
      markup={ArtistRAEditorialTabMarkup}
      subNavOptions={subNavOptions}
      {...queryResult}
    />
  )
}

ArtistRAEditorialTab.propTypes = {
  id: PropTypes.number,
  subNavOptions: artistDetailsSubNavPropType,
}

const ArtistRAEditorialTabMarkup = ({ data, subNavOptions }) => {
  const intl = useIntl()
  const { asPath } = useRouter()

  return (
    <ArtistDetailPageTemplate
      id={data.id}
      contentUrl={data.contentUrl}
      subNavOptions={subNavOptions}
      noIndex
    >
      <Seo
        {...useArtistSEO({
          data,
          url: asPath,
          pageType: ARTIST_PAGE_TYPE.editorial,
        })}
      />
      <PaddedStack bg="grey.80">
        {arrayHasData(data.reviews) && (
          <SubSection
            title={intl.formatMessage(messages.musicReviews)}
            variant={variants.section.secondary}
          >
            <Grid lCols={4} mCols={2} sCols={1} lWrap mWrap sWrap>
              {data.reviews.map((review, i) => (
                <Column key={review.id}>
                  <MusicCard
                    urlFormatter={reviewByIdRoute}
                    position={i + 1}
                    {...review}
                    musicType="review"
                  />
                </Column>
              ))}
            </Grid>
          </SubSection>
        )}
        <ArtistFeaturesFilmsSubSection
          title={intl.formatMessage(dict.features)}
          artistId={data.id}
          type="STANDARD"
        />
        <ArtistFeaturesFilmsSubSection
          title={intl.formatMessage(dict.films)}
          artistId={data.id}
          type="FILM"
        />
      </PaddedStack>
    </ArtistDetailPageTemplate>
  )
}

ArtistRAEditorialTabMarkup.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    country: PropTypes.object,
    facebook: PropTypes.string,
    name: PropTypes.string.isRequired,
    twitter: PropTypes.string,
    instagram: PropTypes.string,
    contentUrl: PropTypes.string.isRequired,
    reviews: PropTypes.arrayOf(PropTypes.object),
    features: PropTypes.arrayOf(PropTypes.object),
    films: PropTypes.arrayOf(PropTypes.object),
    biography: PropTypes.shape({
      blurb: PropTypes.string,
    }),
  }).isRequired,
  subNavOptions: artistDetailsSubNavPropType,
}

export default ArtistRAEditorialTab
