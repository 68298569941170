import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { PersonIcon } from '@resident-advisor/design-system'
import isEmpty from 'lodash/isEmpty'
import { clubByIdRoute } from 'lib/routes'
import messages from '@/messages/events'
import StatCard, { StatList } from '@/components/events/cards/stat'
import trackingIds from '@/tracking/tracking-ids'

const VenuesMostPlayed = ({ venues }) => {
  const { formatMessage } = useIntl()

  if (isEmpty(venues)) {
    return null
  }

  return (
    <StatCard
      Icon={PersonIcon}
      iconLabel={formatMessage(messages.clubsMostPlayed)}
    >
      <StatList
        data-tracking-id={trackingIds.regionsMostPlayed}
        items={venues.map((venue) => ({
          text: venue.name,
          ...clubByIdRoute(venue.contentUrl),
        }))}
      />
    </StatCard>
  )
}

VenuesMostPlayed.propTypes = {
  venues: PropTypes.array,
}

export default VenuesMostPlayed
