import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { SubSection, Column, Grid } from '@resident-advisor/design-system'
import arrayHasData from 'lib/arrayHasData'
import { exchangeByIdRoute, podcastByIdRoute } from 'lib/routes'
import messages from '@/messages/music'
import QueryResultHandler from '@/components/generic/query-result-handler'
import Ad from '@/components/generic/ad'
import MusicCard from '@/components/editorial/cards/music'
import GET_ARTIST_PODCAST_EXCHANGE from './ArtistPodcastExchangeQuery'

const ArtistPodcastAndExchange = ({ artistId }) => {
  const { loading, error, data } = useQuery(GET_ARTIST_PODCAST_EXCHANGE, {
    variables: { id: artistId },
  })

  return (
    <QueryResultHandler
      loading={loading}
      error={error}
      data={data}
      dataKey="artist"
      markup={ArtistPodcastAndExchangeMarkup}
    />
  )
}

ArtistPodcastAndExchange.propTypes = {
  artistId: PropTypes.string.isRequired,
}

const ArtistPodcastAndExchangeMarkup = ({ data: { podcasts, exchanges } }) => {
  const intl = useIntl()
  if (!arrayHasData(exchanges) && !arrayHasData(podcasts)) {
    return null
  }

  return (
    <SubSection.Stacked>
      <Grid mCols={12} sCols={1} mWrap sWrap>
        {arrayHasData(exchanges) && (
          <>
            <Column mSpan={5} sSpan={1}>
              <MusicCard
                urlFormatter={exchangeByIdRoute}
                contentTitle={intl.formatMessage(messages.exchange)}
                {...exchanges[0]}
                musicType="exchange"
              />
            </Column>
            <Column mSpan={1} sSpan={0} />
          </>
        )}
        {arrayHasData(podcasts) && (
          <>
            <Column mSpan={5} sSpan={1}>
              <MusicCard
                urlFormatter={podcastByIdRoute}
                contentTitle={intl.formatMessage(messages.podcast)}
                {...podcasts[0]}
                musicType="podcast"
              />
            </Column>
            <Column mSpan={1} sSpan={0} />
          </>
        )}
        {(!arrayHasData(exchanges) || !arrayHasData(podcasts)) && (
          <Column>
            <Ad variant={Ad.variants.InPageSmall} p={2} />
          </Column>
        )}
      </Grid>
    </SubSection.Stacked>
  )
}

ArtistPodcastAndExchangeMarkup.propTypes = {
  data: PropTypes.shape({
    podcasts: PropTypes.array,
    exchanges: PropTypes.array,
  }).isRequired,
}

export { ArtistPodcastAndExchange as default, ArtistPodcastAndExchangeMarkup }
