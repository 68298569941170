import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import {
  SubSection,
  Grid,
  Column,
  variants,
} from '@resident-advisor/design-system'
import QueryResultHandler from '@/components/generic/query-result-handler'
import FeatureCard from '@/components/editorial/cards/feature'
import GET_ARTIST_FEATURES_FILMS from './GetArtistFeaturesQuery'

const FEATURE_TYPES = {
  STANDARD: 'STANDARD',
  FILM: 'FILM',
}

const ArtistFeaturesFilmsSubSection = ({ artistId, type, title }) => {
  const queryResult = useQuery(GET_ARTIST_FEATURES_FILMS, {
    variables: {
      id: artistId,
      type,
    },
  })

  return (
    <QueryResultHandler
      {...queryResult}
      title={title}
      dataKey="artist.features"
      markup={ArtistFeaturesFilmsSubSectionMarkup}
    />
  )
}

ArtistFeaturesFilmsSubSection.propTypes = {
  artistId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  type: PropTypes.oneOf(Object.values(FEATURE_TYPES)),
  title: PropTypes.string.isRequired,
}

const ArtistFeaturesFilmsSubSectionMarkup = ({ data: features, title }) => (
  <SubSection title={title} variant={variants.section.secondary}>
    <Grid lCols={4} mCols={2} sCols={1} lWrap mWrap sWrap>
      {features.map((feature, i) => (
        <Column key={feature.id}>
          <FeatureCard position={i + 1} {...feature} />
        </Column>
      ))}
    </Grid>
  </SubSection>
)

ArtistFeaturesFilmsSubSectionMarkup.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export { ArtistFeaturesFilmsSubSectionMarkup }
export default ArtistFeaturesFilmsSubSection
