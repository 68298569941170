import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import { getRelativeUri } from 'lib/utils'
import getYear from 'date-fns/getYear'
import { useServerTime } from '@/context/ServerTimeContext'
import { useContentLanguageContext } from '@/context/ContentLanguageContext'
import Listing, {
  CreateListingArchivePagination,
} from '@/components/shared/listing'
import YearlyArchive from '@/components/shared/yearly-archive'
import NewsListingCard from '@/components/editorial/cards/news-listing'
import Ad from '@/components/generic/ad'

const FIRST_NEWS_YEAR = 2001

const FilteredNewsListing = ({
  id,
  baseFilterType,
  listingFilterRouteFormatter,
  createSubNav,
  dataTrackingId,
}) => {
  const { query: routerQuery, asPath } = useRouter()
  const serverTime = useServerTime()
  const { value: language } = useContentLanguageContext()

  const defaultEndYear = getYear(serverTime)

  const getLinkParams = (path, query) =>
    listingFilterRouteFormatter(getRelativeUri(path), query)
  const formatUrl = ({ slug, ...data }) => getLinkParams(asPath, data)

  const baseFilter = {
    type: baseFilterType,
    value: id,
  }
  const baseFilters = getBaseFilters(language, baseFilter)
  const baseAggregationTypes = baseFilters.map((filter) => filter.type)
  const defaultFilters = [
    {
      type: Listing.Aggregations.DateRange,
      value: JSON.stringify({ lte: new Date(serverTime) }),
    },
  ]

  return (
    <Listing
      type={Listing.Type.News}
      aggregations={[Listing.Aggregations.Year]}
      baseFilters={baseFilters}
      defaultFilters={defaultFilters}
      formatUrl={formatUrl}
      sortField={Listing.SortField.Date}
      sortOrder={Listing.SortOrder.Descending}
      createSubNav={createSubNav}
      createContent={({ data }) => (
        <Content
          data={data}
          title={routerQuery?.year}
          dataTrackingId={dataTrackingId}
        />
      )}
      createPagination={CreateListingArchivePagination(
        [...baseAggregationTypes, Listing.Aggregations.Year],
        FIRST_NEWS_YEAR,
        defaultEndYear,
        {
          modes: { year: true },
          getLinkParams,
        }
      )}
    />
  )
}

const Content = ({ data, title, dataTrackingId }) => (
  <YearlyArchive
    Card={(news) => <NewsListingCard key={news.id} {...news} />}
    title={title}
    data={data}
    SecondaryComponent={() => (
      <Ad variant={Ad.variants.MPU_General_Large} justifyContent="flex-end" />
    )}
    secondaryComponentWrapBottom
    as="main"
    data-tracking-id={dataTrackingId}
  />
)

Content.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string,
  dataTrackingId: PropTypes.string,
  dateFormat: PropTypes.string,
  formatTypeUrl: PropTypes.func,
}

const getBaseFilters = (language, baseFilter) => {
  const result = [
    {
      type: Listing.Aggregations.Language,
      value: language,
    },
  ]

  if (baseFilter) {
    result.push(baseFilter)
  }

  return result
}

FilteredNewsListing.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  baseFilterType: PropTypes.oneOf([
    Listing.Aggregations.Artist,
    Listing.Aggregations.Club,
    Listing.Aggregations.Promoter,
  ]),
  listingFilterRouteFormatter: PropTypes.func.isRequired,
  createSubNav: PropTypes.func.isRequired,
  dataTrackingId: PropTypes.string,
}

export default FilteredNewsListing
