import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import Redirect from '@/components/generic/redirect'
import { EventsListingType } from '@/components/events/sections/filtered-events-listing'
import useIsPastYear from '@/hooks/useIsPastYear'
import ArtistEventsTab, {
  buildPastEventsRedirectUrl,
} from '../artist-events-tab'

const ArtistTourDatesTab = ({ contentUrl, slug, subNavOptions }) => {
  const {
    query: { year, country },
  } = useRouter()
  const isPastYear = useIsPastYear(year)

  if (isPastYear) {
    // They've requested a year in the past, so redirect them to /past-events
    return (
      <Redirect
        to={buildPastEventsRedirectUrl(contentUrl, { year, country })}
      />
    )
  }

  if (
    !subNavOptions.upcomingEventsLoading &&
    !subNavOptions.showUpcomingEventsTab
  ) {
    // They're requested a current/future year but there are no events...
    // so redirect them to past-events without the year param
    return (
      <Redirect
        to={buildPastEventsRedirectUrl(contentUrl, {
          country,
        })}
      />
    )
  }

  return (
    <ArtistEventsTab
      slug={slug}
      subNavOptions={subNavOptions}
      eventsListingType={EventsListingType.Upcoming}
    />
  )
}

ArtistTourDatesTab.propTypes = {
  contentUrl: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  subNavOptions: PropTypes.object.isRequired,
}

export default ArtistTourDatesTab
