import PropTypes from 'prop-types'
import {
  Alignment,
  Column,
  Grid,
  variants,
  deviceWidths as media,
} from '@resident-advisor/design-system'
import {
  useGetImageProperties,
  PROFILE_IMAGE_TYPES,
  PROFILE_IMAGE_DIMENSIONS,
} from 'lib/profileImageUtils'
import styled from 'styled-components'
import unescape from 'lodash/unescape'
import ResponsiveImage from '@/components/generic/responsive-image'
import Link from '@/components/generic/link'
import FollowButton, {
  FOLLOW_BUTTON_TYPES,
} from '@/components/shared/follow-button'
import testIds from '@/enums/testIds'

const IMAGE_SIZES = { m: '33vw' }
const IMAGE_DIMENSIONS = [PROFILE_IMAGE_DIMENSIONS.desktop]

const ProfileCard = ({
  id,
  name,
  contentUrl,
  image,
  isFollowing,
  lazyRootRef,
  imageType,
  followButtonType,
  urlFormatter,
  followerCount,
  fill,
  ProfileTypeComponent,
}) => {
  const properties = useGetImageProperties(imageType)
  const span = fill ? 3 : 2

  return (
    <Alignment flexDirection="column">
      <Grid lCols={4} mCols={3} sCols={3} sWrap gridGap={{ s: 10, m: 20 }}>
        <Column lSpan={2} mSpan={span} sSpan={span}>
          <Link {...urlFormatter(contentUrl)}>
            <ResponsiveImage
              borderRadius="circular"
              url={image}
              alt={name}
              sizes={IMAGE_SIZES}
              properties={properties}
              dimensions={IMAGE_DIMENSIONS}
              lazyRootRef={lazyRootRef}
            />
          </Link>
        </Column>
        <Column lSpan={2} mSpan={span} sSpan={span}>
          <Alignment
            flexDirection="column"
            alignContent="center"
            justifyContent="center"
            height="100%"
          >
            <>
              {ProfileTypeComponent && (
                <StyledAlignment data-testid={testIds.profileTypeComponent}>
                  {ProfileTypeComponent}
                </StyledAlignment>
              )}
              <StyledAlignment my={{ s: 0, m: 2 }}>
                <Link
                  color="primary"
                  variant={variants.text.heading.m}
                  {...urlFormatter(contentUrl)}
                >
                  {unescape(name)}
                </Link>
              </StyledAlignment>
              <StyledAlignment>
                <FollowButton
                  id={id}
                  isFollowing={isFollowing}
                  variant={variants.button.tertiary}
                  type={followButtonType}
                  followerCount={followerCount}
                />
              </StyledAlignment>
            </>
          </Alignment>
        </Column>
      </Grid>
    </Alignment>
  )
}

const StyledAlignment = styled(Alignment)`
  width: 100%;
  align-items: center;
  flex-direction: column;
  justify-items: center;
  ${media.m`
      align-items: start;
      justify-items: start;
  `}
`

ProfileCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  name: PropTypes.string.isRequired,
  contentUrl: PropTypes.string.isRequired,
  image: PropTypes.string,
  isFollowing: PropTypes.bool,
  followerCount: PropTypes.number,
  lazyRootRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  urlFormatter: PropTypes.func.isRequired,
  imageType: PropTypes.oneOf(Object.values(PROFILE_IMAGE_TYPES)).isRequired,
  followButtonType: PropTypes.oneOf(Object.values(FOLLOW_BUTTON_TYPES))
    .isRequired,
  fill: PropTypes.bool,
  ProfileTypeComponent: PropTypes.object,
}

ProfileCard.defaultProps = {
  fill: false,
}

export default ProfileCard
