import { Alignment, variants, Text } from '@resident-advisor/design-system'
import PropTypes from 'prop-types'
import Link from '@/components/generic/link'

const StatList = ({ items, ...props }) => (
  <Alignment flexDirection="column" pt={3} {...props}>
    {items
      .map((item, index) => {
        if (item.text && item.href) {
          return (
            <Link
              key={index}
              color="primary"
              variant={variants.text.heading.m}
              href={item.href}
              as={item.as}
            >
              {item.text}
            </Link>
          )
        }
        if (item.text) {
          return (
            <Text key={index} color="primary" variant={variants.text.heading.m}>
              {item.text}
            </Text>
          )
        }

        return null
      })
      .filter((item) => item !== null)}
  </Alignment>
)

StatList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        text: PropTypes.string,
        href: PropTypes.string,
        as: PropTypes.string,
      }),
      PropTypes.exact({
        text: PropTypes.string,
      }),
    ])
  ),
}

export default StatList
