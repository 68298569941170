import { useIntl } from 'react-intl'
import { SubSection, variants } from '@resident-advisor/design-system'
import { ThemeProvider } from 'styled-components'
import PropTypes, { InferProps } from 'prop-types'
import HorizontalList from '@/components/generic/horizontal-list'
import ArtistCard from '@/components/events/cards/artist'
import { light } from '@/themes'
import messages from '@/messages/music'

import trackingIds from '@/tracking/tracking-ids'

const RelatedArtists: React.FC<RelatedArtistsProps> = ({
  artists,
  LinkComponent,
}) => {
  const intl = useIntl()

  return (
    <ThemeProvider theme={light}>
      <SubSection.Stacked
        title={intl.formatMessage(messages.relatedArtists)}
        variant={variants.section.secondary}
        data-tracking-id={trackingIds.relatedArtists}
        options={LinkComponent}
      >
        <HorizontalList
          mCols={3}
          mWrap
          lWrap
          Card={ArtistCard}
          items={artists}
        />
      </SubSection.Stacked>
    </ThemeProvider>
  )
}

const RelatedArtistsPropTypes = {
  artists: PropTypes.array.isRequired,
  LinkComponent: PropTypes.node,
}

RelatedArtists.propTypes = RelatedArtistsPropTypes
type RelatedArtistsProps = InferProps<typeof RelatedArtistsPropTypes>

RelatedArtists.displayName = 'RelatedArtists'

export default RelatedArtists
