import { useRouter } from 'next/router'
import PropTypes from 'prop-types'

import { queryStringSetRelative } from 'lib/queryStringSet'
import useIsPastYear from '@/hooks/useIsPastYear'
import Redirect from '@/components/generic/redirect'

const ArtistEventsRedirects = ({ contentUrl }) => {
  const { query } = useRouter()

  const isPastYear = useIsPastYear(query.year)
  const redirectUrl = isPastYear
    ? buildPastEventsRedirectUrl(contentUrl, query)
    : buildTourDatesEventsRedirectUrl(contentUrl, query)

  return <Redirect data-testid="redirect" to={redirectUrl} />
}

const buildEventsRedirectUrl = (contentUrl, query, pageType) => {
  // ignore the slug param from artist tab routing
  const { slug, ...queryWithoutSlug } = query
  return queryStringSetRelative(`${contentUrl}/${pageType}`, queryWithoutSlug)
}

const buildPastEventsRedirectUrl = (contentUrl, query) =>
  buildEventsRedirectUrl(contentUrl, query, 'past-events')

const buildTourDatesEventsRedirectUrl = (contentUrl, query) =>
  buildEventsRedirectUrl(contentUrl, query, 'tour-dates')

ArtistEventsRedirects.propTypes = {
  contentUrl: PropTypes.string.isRequired,
}

export { buildPastEventsRedirectUrl }
export default ArtistEventsRedirects
